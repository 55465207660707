<template>
	<div class="section__news-all">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-8">
					<router-link :to="{ name: 'userSite' }" class="back-button">
						<svg>
							<use href="#icon_back"></use>
						</svg>
						<strong>
							На головну
						</strong>
					</router-link>
					<h2>
						Перебіг кампанії
					</h2>
					<template v-if="loading">
						<ContentLoader
							v-for="(n, i) in 2"
							:key="i"
							viewBox="0 0 400 200"
							width="400"
							height="200"
							title="Loading news..."
						>
							<rect
								x="0"
								y="9.93"
								rx="5"
								ry="5"
								width="143.55"
								height="86.59"
							/>
							<rect
								x="152.84"
								y="9.67"
								rx="0"
								ry="0"
								width="168.72"
								height="12.12"
							/>
							<rect
								x="152.84"
								y="25.67"
								rx="0"
								ry="0"
								width="129"
								height="9"
							/>

							<rect
								x="0"
								y="107"
								rx="5"
								ry="5"
								width="143.55"
								height="86.59"
							/>
							<rect
								x="152.84"
								y="107"
								rx="0"
								ry="0"
								width="168.72"
								height="12.12"
							/>
							<rect
								x="152.84"
								y="123"
								rx="0"
								ry="0"
								width="129"
								height="9"
							/>
						</ContentLoader>
					</template>
					<div
						class="news__item"
						v-for="(item, i) in currentPosts"
						:key="i"
					>
						<div
							class="thumb"
							:style="{
								'background-image': 'url(' + item.picture + ')',
							}"
						>
							<router-link
								:to="{
									name: 'post-single',
									params: { postslug: item.slug },
								}"
							>
							</router-link>
						</div>
						<div class="content">
							<div class="date" v-if="item.date">
								{{ item.date._seconds | moment("DD MMM") }}
							</div>
							<div class="title">
								<router-link
									:to="{
										name: 'post-single',
										params: { postslug: item.slug },
									}"
								>
									{{ item.title }}
								</router-link>
							</div>
						</div>
					</div>
					<nav aria-label="..." v-if="pages > 1">
						<ul class="pagination">
							<li
								class="page-iteme"
								v-for="(p, i) in pages"
								:key="i"
								
							>
								<span
									class="page-link"
									v-if="Number(i + 1) == page"
									:class="{active:i +1== page}"
								>
									{{ i + 1 }}
									<span class="sr-only">(current)</span>
								</span>
								<a class="page-link"  href="#" @click.prevent="page = Number(i + 1)" v-else>{{
									i + 1
								}}</a>
							</li>
						</ul>
					</nav>
				</div>
				<div class="col-12 col-md-4">
					<sidebarBio :bio="bio" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebarBio from "@/components/blogBio.vue";
import { ContentLoader } from "vue-content-loader";
export default {
	props: ["posts", "bio", "loading"],
	data() {
		return {
			page: 1,
			per_page: 3,
		};
	},
	components: {
		sidebarBio,
		ContentLoader,
	},
	computed: {
		pages: function() {
			return Math.ceil(this.posts.length / this.per_page);
		},
		currentPosts: function() {
			var posts = [];
			if (this.posts) {
				for (var i = 0; i < this.posts.length; i++) {
					var offset = 0;
					if (this.page == 1 && i < this.per_page) {
						posts.push(this.posts[i]);
					}

					if (this.page > 1) {
						offset = this.per_page * Number(this.page - 1);

						if (i + 1 > offset && i + 1 < offset + this.per_page) {
							posts.push(this.posts[i]);
						}
					}
				}
			}
			return posts;
		},
	},
};
</script>

<style lang="scss" scoped>
.pagination{
	a ,span{
		color:var(--theme-primary);
	}
	.active {
		background: var(--theme-primary);
		color:#fff!important;
	}
}
.section {
	&__news-all {
		margin-top: 120px;
		h2 {
			margin-bottom: 40px;
		}
		.news__item {
			display: flex;
			margin-bottom: 30px;
			@media (max-width: 600px) {
				flex-direction: column;
			}
			.date {
				color: var(--theme-primary);
			}
			.thumb {
				width: 220px;
				height: 180px;
				min-width: 220px;
				background-color: #e7e7e7;
				margin-right: 30px;
				background-size: cover;
				background-position: center;
				position: relative;
				@media (max-width: 600px) {
					margin-bottom: 10px;
				}
				a {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					display: block;
				}
			}
			.title {
				font-size: 20px;
				a {
					color: #000;
					font-weight: 600;
					transition: 0.3s;
					&:hover {
						transition: 0.3s;
						color: var(--theme-primary);
						text-decoration: none;
						border-bottom: 1px solid;
					}
				}
			}
		}
	}
}
</style>
