<template>
	<div class="bio__sidebar">
		<div
			class="bio__photo"
			:style="{
				backgroundImage: 'url(' + bio.photo + ')',
			}"
		></div>
		<div class="bio__name">
			<strong>
				{{ bio.name }}
			</strong>
		</div>
		<div class="bio__district">
			<span> Округ № {{ bio.district }} </span>
		</div>
		<div class="bio__slogan">
			{{ bio.slogan }}
		</div>
	</div>
</template>

<script>
export default {
	props: ["bio"],
};
</script>

<style lang="scss" scoped>
.bio {
	&__slogan {
		display: block;
		margin: 20px 0;
		padding: 10px;
		font-size: 18px;
		font-weight: 500;
		font-style: italic;
		//border-top: 1px solid var(--theme-primary);
		// border-bottom: 1px solid var(--theme-primary);
		background: rgba(#e7e7e7, 0.4);
		border-radius: 8px;
		padding-left: 30px;
		position: relative;
		&::before {
			content: '"';
			position: absolute;
			display: inline;
			font-size: 64px;
			line-height: 1;
			top: -15px;
			left: 0px;
			color: var(--theme-primary);
		}
	}
	&__sidebar {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	&__photo {
		width: 96px;
		height: 96px;
		border-radius: 50%;
		background-size: cover;
		margin-bottom: 10px;
		box-shadow: 0px 0px 0px 2px var(--theme-primary);
	}
}
</style>
