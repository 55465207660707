<template>
	<div class="bio__full">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-md-10 col-lg-9">
					<div class="d-flex w-100 justify-content-center mb-4">
						<router-link
							:to="{ name: 'userSite' }"
							class="back-button"
						>
							<svg>
								<use href="#icon_back"></use>
							</svg>
							<strong>
								На головну
							</strong>
						</router-link>
					</div>
					<div
						class="bio__photo"
						:style="{
							backgroundImage: 'url(' + hero.photo + ')',
						}"
					></div>
					<div class="bio__name text-center">
						<span v-if="hero.name">
							{{ hero.name }}
						</span>
					</div>
					<h1 class="text-center">
						Передвиборча програма
					</h1>
					<div class="formatted-content" v-html="bio.text"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["bio", "hero"],
};
</script>

<style lang="scss" scoped>
.formatted-content {
	padding: 40px 40px;
	border-top: 1px solid var(--theme-primary);
	border-bottom: 1px solid var(--theme-primary);
	margin-bottom: 100px;
	@media(max-width: 700px){
		padding: 40px 0px;
	}
}
.bio {
	&__name {
		color: var(--theme-primary);
		margin-bottom: 5px;
	}
	&__photo {
		width: 120px;
		height: 120px;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		margin: 0px auto 10px;
	}
	&__full {
		margin-top: 120px;
		h1 {
			color: #000;
			margin-bottom: 40px;
		}
	}
}
</style>
