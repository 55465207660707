var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"section__hero",class:_vm.hero.theme,attrs:{"id":"hero"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-sm-7 col-md-6 order-2 order-md-1"},[_c('div',{staticClass:"hero__district"},[_c('div',{staticClass:"icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon_checked"}})])]),_c('div',{staticClass:"text"},[_c('strong',{staticClass:"color-yellow"},[_vm._v(" № "),(_vm.hero.list_number)?_c('i',[_vm._v(_vm._s(_vm.hero.list_number))]):_c('i',[_vm._v("1")])]),_c('span',{staticClass:"color-white"},[(_vm.hero.district)?_c('span',[_vm._v("Округ № "+_vm._s(_vm.hero.district)+", ")]):_vm._e(),(_vm.hero.area)?_c('span',[_vm._v(_vm._s(_vm.hero.area))]):_vm._e()])])]),_c('h1',{staticClass:"color-white"},[(_vm.hero.name)?_c('strong',[_vm._v(" "+_vm._s(_vm.hero.name)+" ")]):_vm._e()]),(_vm.hero.slogan)?_c('blockquote',{staticClass:"blockquote color-white"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.hero.slogan)+" ")])]):_vm._e(),_c('router-link',{staticClass:"btn btn-primary ",attrs:{"to":{ hash: '#program' }}},[_vm._v(" Програма кандидата ")])],1),_c('div',{staticClass:"col-12 col-sm-5 col-md-6 order-1 order-md-2"},[_c('div',{staticClass:"hero__photo",style:({
							backgroundImage: 'url(' + _vm.hero.photo + ')',
						})})])])])]),_c('div',{staticClass:"section__news",attrs:{"id":"news"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[(_vm.loading)?_vm._l((3),function(n){return _c('div',{key:n,staticClass:"col-12 col-sm-6 col-md-4 news__item"},[_c('ContentLoader',{attrs:{"viewBox":"0 0 500 280","height":"380","width":"280","primaryColor":"#e0e0e0","secondaryColor":"#e7e7e7"}},[_c('rect',{attrs:{"x":"3","y":"3","rx":"0","ry":"0","width":"300","height":"180"}}),_c('rect',{attrs:{"x":"6","y":"190","rx":"0","ry":"0","width":"292","height":"20"}}),_c('rect',{attrs:{"x":"4","y":"215","rx":"0","ry":"0","width":"239","height":"20"}}),_c('rect',{attrs:{"x":"4","y":"242","rx":"0","ry":"0","width":"274","height":"20"}})])],1)}):_vm._l((_vm.news),function(item,i){return _c('div',{key:i,staticClass:"col-12 col-sm-6 col-md-4 news__item"},[(item)?_c('div',{staticClass:"thumb",style:({
								'background-image':
									'url(' + item.picture + ')',
							})},[(item)?_c('router-link',{attrs:{"to":{
									name: 'post-single',
									params: { postslug: item.slug },
								}}}):_vm._e(),(item)?_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date._seconds,"DD MMM"))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"title"},[(item)?_c('router-link',{attrs:{"to":{
									name: 'post-single',
									params: { postslug: item.slug },
								}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)])})],2),(_vm.news.length > 0 && !_vm.loading)?_c('div',{staticClass:"row justify-content-center mt-4"},[_c('router-link',{staticClass:"btn mt-4",attrs:{"to":{ name: 'posts-all' }}},[_vm._v(" Усі новини ")])],1):_vm._e()])]),_c('div',{staticClass:"section__biography",attrs:{"id":"bio"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12 col-md-8"},[_c('div',{staticClass:"formatted-content"},[(_vm.bio.text)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.bio.text.slice(0, 480) + '...')}}):_vm._e()]),_c('p',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'userSiteBio' }}},[_vm._v(" Читати повну біографію ")])],1)])])])]),_c('div',{staticClass:"section__changes",attrs:{"id":"program"}},[_c('img',{staticClass:"changes_ua",attrs:{"src":require("@/assets/ua.png"),"alt":""}}),_c('div',{staticClass:"container"},[_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8"},[_c('div',{staticClass:"row"},_vm._l((_vm.changes.thesis),function(item,i){return _c('div',{key:i,staticClass:"col-12 col-sm-6"},[_c('div',{staticClass:"changes__item"},[(item.text)?_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(i + 1)+" ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(item.text)+" ")])])])}),0)]),_c('div',{staticClass:"col-12 col-lg-4"})]),_c('div',{staticClass:"row justify-content-center"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'userSiteProgram' }}},[_vm._v("Повний текст програми")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v(" Перебіг кампанії ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-md-4"},[_c('h2',[_vm._v(" Біографія ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('h2',{staticClass:"mb-4"},[_vm._v(" Виборча програма ")])])])}]

export { render, staticRenderFns }