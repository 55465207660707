<template>
	<div class="post__single" v-if="post">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-8">
					<router-link
						:to="{ name: 'posts-all' }"
						class="back-button"
					>
						<svg>
							<use href="#icon_back"></use>
						</svg>
						<strong>
							До всіх новин
						</strong>
					</router-link>
					<template v-if="loading">
						<ContentLoader
							viewBox="0 0 400 100"
							height="100"
							width="400"
						>
							<rect
								x="0"
								y="13"
								rx="4"
								ry="4"
								width="400"
								height="9"
							/>
							<rect
								x="0"
								y="29"
								rx="4"
								ry="4"
								width="100"
								height="8"
							/>
							<rect
								x="0"
								y="50"
								rx="4"
								ry="4"
								width="400"
								height="10"
							/>
							<rect
								x="0"
								y="65"
								rx="4"
								ry="4"
								width="400"
								height="10"
							/>
							<rect
								x="0"
								y="79"
								rx="4"
								ry="4"
								width="100"
								height="10"
							/>
						</ContentLoader>
					</template>
					<h1 v-if="post">
						{{ post.title }}
					</h1>
					<div class="post__date" v-if="post.date">
						{{ post.date._seconds | moment("calendar") }}
					</div>
					<template v-if="loading">
						<ContentLoader
							viewBox="0 0 400 160"
							height="160"
							width="400"
						>
							<rect
								x="0"
								y="13"
								rx="4"
								ry="4"
								width="400"
								height="9"
							/>
							<rect
								x="0"
								y="29"
								rx="4"
								ry="4"
								width="100"
								height="8"
							/>
							<rect
								x="0"
								y="50"
								rx="4"
								ry="4"
								width="400"
								height="10"
							/>
							<rect
								x="0"
								y="65"
								rx="4"
								ry="4"
								width="400"
								height="10"
							/>
							<rect
								x="0"
								y="79"
								rx="4"
								ry="4"
								width="100"
								height="10"
							/>
							<rect
								x="0"
								y="99"
								rx="5"
								ry="5"
								width="400"
								height="200"
							/>
						</ContentLoader>
					</template>
					<div class="formatted-content" v-if="post">
						<div v-html="post.content"></div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<sidebarBio :bio="bio" />
				</div>
			</div>
		</div>
		<div class="section__news" id="news">
			<div class="container">
				<div class="row">
					<h2>
						Читайте також
					</h2>
				</div>
				<div class="row">
					<div
						class="col-12 col-sm-6 col-md-4 news__item"
						v-for="(item, i) in posts"
						:key="item.slug"
						:class="{ 'd-none': item.slug == post.slug || i > 2 }"
					>
						<div
							class="thumb"
							:style="{
								'background-image': 'url(' + item.picture + ')',
							}"
						>
							<router-link
								:to="{
									name: 'post-single',
									params: { postslug: item.slug },
								}"
							>
							</router-link>
							<div class="date">
								{{ item.date._seconds | moment("DD MMM") }}
							</div>
						</div>

						<div class="title">
							<router-link
								:to="{
									name: 'post-single',
									params: { postslug: item.slug },
								}"
							>
								{{ item.title }}
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebarBio from "@/components/blogBio.vue";
import { ContentLoader } from "vue-content-loader";
export default {
	props: ["post", "posts", "bio", "loading"],
	components: {
		sidebarBio,
		ContentLoader,
	},
};
</script>

<style lang="scss">
@import "../../scss/_typography.scss";
$yellow: #ffdb38;
@import "../../scss/_sections.scss";
.post {
	&__date {
		margin-bottom: 30px;
		color: var(--theme-primary);
	}
	&__single {
		margin-top: 120px;
		h1 {
			line-height: 1.2;
			font-weight: 600;
			font-size: 32px;
			margin-bottom: 10px;
		}

		.formatted-content {
			p {
				font-size: 18px !important;
			}
			a {
				color: var(--theme-primary) !important;
			}
			margin-bottom: 80px;
		}
	}
}
</style>
