<template>
	<main>
		<div class="section__hero" id="hero" :class="hero.theme">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-12 col-sm-7 col-md-6 order-2 order-md-1">
						<div class="hero__district">
							<div class="icon">
								<svg>
									<use href="#icon_checked" />
								</svg>
							</div>
							<div class="text">
								<strong class="color-yellow">
									№
									<i v-if="hero.list_number">{{
										hero.list_number
									}}</i>
									<i v-else>1</i>
								</strong>
								<span class="color-white">
									<span v-if="hero.district"
										>Округ № {{ hero.district }},
									</span>
									<span v-if="hero.area">{{
										hero.area
									}}</span>
								</span>
							</div>
						</div>
						<h1 class="color-white">
							<strong v-if="hero.name">
								{{ hero.name }}
							</strong>
						</h1>
						<blockquote
							class="blockquote color-white"
							v-if="hero.slogan"
						>
							<p class="mb-0">
								{{ hero.slogan }}
							</p>
						</blockquote>
						<router-link
							:to="{ hash: '#program' }"
							class="btn btn-primary "
						>
							Програма кандидата
						</router-link>
					</div>
					<div class="col-12 col-sm-5 col-md-6 order-1 order-md-2">
						<div
							class="hero__photo"
							:style="{
								backgroundImage: 'url(' + hero.photo + ')',
							}"
						></div>
					</div>
				</div>
			</div>
		</div>
		<div class="section__news" id="news">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h2>
							Перебіг кампанії
						</h2>
					</div>
				</div>
				<div class="row">
					<template v-if="loading">
						<div
							class="col-12 col-sm-6 col-md-4 news__item"
							v-for="n in 3"
							:key="n"
						>
							<ContentLoader
								viewBox="0 0 500 280"
								height="380"
								width="280"
								primaryColor="#e0e0e0"
								secondaryColor="#e7e7e7"
							>
								<rect
									x="3"
									y="3"
									rx="0"
									ry="0"
									width="300"
									height="180"
								></rect>
								<rect
									x="6"
									y="190"
									rx="0"
									ry="0"
									width="292"
									height="20"
								></rect>
								<rect
									x="4"
									y="215"
									rx="0"
									ry="0"
									width="239"
									height="20"
								></rect>
								<rect
									x="4"
									y="242"
									rx="0"
									ry="0"
									width="274"
									height="20"
								></rect>
							</ContentLoader>
						</div>
					</template>
					<template v-else>
						<div
							class="col-12 col-sm-6 col-md-4 news__item"
							v-for="(item, i) in news"
							:key="i"
						>
							<div
								class="thumb"
								v-if="item"
								:style="{
									'background-image':
										'url(' + item.picture + ')',
								}"
							>
								<router-link
									v-if="item"
									:to="{
										name: 'post-single',
										params: { postslug: item.slug },
									}"
								>
								</router-link>
								<div class="date" v-if="item">
									{{ item.date._seconds | moment("DD MMM") }}
								</div>
							</div>

							<div class="title">
								<router-link
									v-if="item"
									:to="{
										name: 'post-single',
										params: { postslug: item.slug },
									}"
								>
									{{ item.title }}
								</router-link>
							</div>
						</div>
					</template>
				</div>
				<div
					class="row justify-content-center mt-4"
					v-if="news.length > 0 && !loading"
				>
					<router-link :to="{ name: 'posts-all' }" class="btn mt-4">
						Усі новини
					</router-link>
				</div>
			</div>
		</div>
		<div class="section__biography" id="bio">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-4">
						<h2>
							Біографія
						</h2>
					</div>
					<div class="col-12 col-md-8">
						<div class="formatted-content">
							<div
								v-if="bio.text"
								v-html="bio.text.slice(0, 480) + '...'"
							></div>
						</div>
						<p>
							<router-link
								:to="{ name: 'userSiteBio' }"
								class="btn btn-primary"
							>
								Читати повну біографію
							</router-link>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="section__changes" id="program">
			<img src="@/assets/ua.png" alt="" class="changes_ua" />
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-4">
						<h2 class="mb-4">
							Виборча програма
						</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-8">
						<div class="row">
							<div
								class="col-12 col-sm-6"
								v-for="(item, i) in changes.thesis"
								:key="i"
							>
								<div class="changes__item">
									<div class="number" v-if="item.text">
										{{ i + 1 }}
									</div>
									<p>
										{{ item.text }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-4"></div>
				</div>
				<div class="row justify-content-center">
					<router-link
						:to="{ name: 'userSiteProgram' }"
						class="btn btn-primary"
						>Повний текст програми</router-link
					>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
	props: ["hero", "bio", "news", "changes", "loading"],
	data() {
		return {};
	},
	components: {
		ContentLoader,
	},
};
</script>

<style lang="scss" scoped>
$yellow: #ffdb38;
@import "../../scss/bootstrap.min.css";
@import "../../scss/_buttons.scss";
@import "../../scss/_typography.scss";
@import "../../scss/_hero.scss";
@import "../../scss/_sections.scss";

body {
	font-family: "Neue Haas Unica", Helvetica, Arial, sans-serif;
}
</style>
