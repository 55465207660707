<template>
	<div>
		<siteHeader v-show="!no_data && !isLoading" :hero="hero" />
		<userHomepage
			v-if="$route.name == 'userSite' && !no_data && !isLoading"
			:hero="hero"
			:bio="bio"
			:news="three_news"
			:loading="newsLoading"
			:changes="changes"
		/>
		<userBio
			v-if="$route.name == 'userSiteBio' && !no_data && !isLoading"
			:bio="bio"
			:hero="hero"
		/>
		<userProgram
			v-if="$route.name == 'userSiteProgram' && !no_data && !isLoading"
			:bio="changes"
			:hero="hero"
		/>
		<postSIngle
			v-if="$route.name == 'post-single' && !no_data && !isLoading"
			:post="selectedPost"
			:posts="news"
			:bio="hero"
			:loading="newsLoading"
		/>
		<postsAll
			v-if="$route.name == 'posts-all' && !no_data && !isLoading"
			:posts="news"
			:bio="hero"
			:loading="newsLoading"
		/>

		<contactsSection :hero="contacts" v-if="!no_data && !isLoading" />
		<icons />
		<cssLoader v-show="isLoading" />
		<noData v-show="$route.name == 'not-found'" />
		<footer>
			<div class="container">
				<div class="row align-items-center">
					<div class="col-12 col-md-6">
						<div class="footer__logo d-flex align-items-center">
							<img
								v-if="hero && hero.theme == 'sluga'"
								src="@/assets/img/logo.svg"
								height="64"
								alt=""
								srcset=""
							/>
							<img
								v-if="hero && hero.theme == 'zamajbutnje'"
								src="@/assets/img/politichna-partiya-za-majbutne.svg"
								height="48"
								alt=""
								srcset=""
							/>
							<span v-if="hero && hero.name">
								{{ hero.name }}
							</span>
						</div>
					</div>
					<div class="col-12 col-md-6 text-right">
						<div
							class="d-flex footer__created-by justify-content-end"
						>
							Створено на платформі
							<a href="https://winwin.kr.ua/" target="_blank"
								>WinWin</a
							>
						</div>
					</div>
				</div>
			</div>
		</footer>

		<div class="error-loading" v-show="dataError && !isLoading">
			<h3>
				Помилка завантаження
			</h3>
			<div class="cta">
				<button class="btn btn-secondary" @click="getData">
					Спробувати ще раз
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import icons from "@/components/icons.vue";
import cssLoader from "@/components/cssLoader.vue";
import noData from "@/components/noData.vue";
import contactsSection from "@/components/contactsSection.vue";
import siteHeader from "@/layout/header.vue";
import userHomepage from "@/views/subPages/userSiteHome.vue";
import userBio from "@/views/subPages/userSiteBio.vue";
import userProgram from "@/views/subPages/userSiteProgram.vue";

import postSIngle from "@/views/subPages/userSitePostSingle.vue";
import postsAll from "@/views/subPages/userSitePostsAll.vue";
export default {
	data() {
		return {
			// theme: "maybach",

			dataError: false,
			no_data: false,
			newsLoading: false,
			isLoading: true,
			hero: {},
			bio: {},
			//api_base: "http://localhost:5001/winwin-43992/us-central1/api",
			api_base: "https://us-central1-winwin-43992.cloudfunctions.net/api",
			news: [],
			contacts: {},
			changes: [],
		};
	},
	components: {
		noData,
		userHomepage,
		siteHeader,
		icons,
		cssLoader,
		userBio,
		postSIngle,
		postsAll,
		contactsSection,
		userProgram,
	},
	computed: {
		three_news: function() {
			var news = [];
			for (var i = 0; i < 4; i++) {
				news.push(this.news[i]);
			}
			return news;
		},
		selectedPost: function() {
			var post = {};
			var slug = this.$route.params.postslug;
			for (var i = 0; i < this.news.length; i++) {
				if (this.news[i].slug === slug) {
					post = this.news[i];
				}
			}
			return post;
		},
	},
	methods: {
		getNews: function(id) {
			this.newsLoading = true;
			this.newsError = false;
			var url = this.api_base + "/site/getPostsById/?owner=" + id;
			fetch(url, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
				},
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					console.log(data);
					if (data) {
						this.newsLoading = false;
						this.news = data;
					} else {
						this.newsLoading = false;
					}
				})
				.catch((error) => {
					console.log(error);
					this.newsLoading = false;
					this.newsError = true;
				});
		},
		getData: function() {
			this.isLoading = true;
			this.dataError = false;
			var slug = this.$route.params.slug;
			var url = this.api_base + "/site/?slug=" + slug;
			fetch(url, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
				},
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.hero) {
						this.getNews(data.user_id);
						this.isLoading = false;
						this.hero = data.hero;
						this.bio = data.bio;
						this.contacts = data.contacts;
						this.changes = data.program;
						this.getStyle();
						var hash = this.$route.hash;
						// console.log(hash);
						window.setTimeout(() => {
							this.scrollToOnLoad(hash);
						}, 600);
					} else {
						this.no_data = true;
						this.$router.push({
							name: "not-found",
						});
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.dataError = true;
					console.log(error);
				});
		},
		getStyle: function() {
			function setThemeVar(name, value, unit) {
				var rootStyles = document.styleSheets[0].cssRules[0].style;
				if (rootStyles) {
					rootStyles.setProperty(
						"--theme-" + name,
						value + (unit || "")
					);
				}
			}
			if (this.hero && this.hero.theme == "zamajbutnje") {
				setThemeVar("primary", "#5B1389");
				setThemeVar("accent", "#4CD43C");
				setThemeVar("primary-gradient", "#5B1389");
				setThemeVar("secondary-gradient", "#5B1389");
				setThemeVar("headerBg", "#fff");
				setThemeVar("headerStickyBg", "#fff");
				setThemeVar("headerLinksColor", "#5B1389");
				setThemeVar("primaryButtonColor", "#ffffff");
				setThemeVar("linkONWhiteColor", "#5A1B86");
			}
			if (this.hero && this.hero.theme !== "zamajbutnje") {
				setThemeVar("primary", "#3eb253");
				setThemeVar("accent", "#FEDA4A");
				setThemeVar("primary-gradient", "#3eb253");
				setThemeVar("secondary-gradient", "#3eb253");
				setThemeVar("headerBg", "rgba(0,0,0,0)");
				setThemeVar("headerStickyBg", "#3eb253");
				setThemeVar("headerLinksColor", "#fff");
				setThemeVar("primaryButtonColor", "#000");
				setThemeVar("linkONWhiteColor", "#23A53C");
			}
		},
		scrollToOnLoad: function(element) {
			if (element) {
				var d = document.querySelector(element);
				var topPos = d.offsetTop;
				this.$nextTick(() => {
					//window.scrollTo(0, topPos);
					window.scrollTo({
						top: topPos,
						behavior: "smooth",
					});
					console.log(topPos);
				});
			}
		},
	},
	async beforeMount() {},
	async mounted() {
		// When the user scrolls the page, execute myFunction
		await this.getData();
		window.onscroll = function() {
			stickHeader();
		};

		// Get the header
		var header = document.getElementById("header");

		// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
		function stickHeader() {
			if (window.pageYOffset > 0) {
				header.classList.add("sticky");
			} else {
				header.classList.remove("sticky");
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.error-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	flex-direction: column;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 999;
}
</style>
