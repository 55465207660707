var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section__news-all"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('router-link',{staticClass:"back-button",attrs:{"to":{ name: 'userSite' }}},[_c('svg',[_c('use',{attrs:{"href":"#icon_back"}})]),_c('strong',[_vm._v(" На головну ")])]),_c('h2',[_vm._v(" Перебіг кампанії ")]),(_vm.loading)?_vm._l((2),function(n,i){return _c('ContentLoader',{key:i,attrs:{"viewBox":"0 0 400 200","width":"400","height":"200","title":"Loading news..."}},[_c('rect',{attrs:{"x":"0","y":"9.93","rx":"5","ry":"5","width":"143.55","height":"86.59"}}),_c('rect',{attrs:{"x":"152.84","y":"9.67","rx":"0","ry":"0","width":"168.72","height":"12.12"}}),_c('rect',{attrs:{"x":"152.84","y":"25.67","rx":"0","ry":"0","width":"129","height":"9"}}),_c('rect',{attrs:{"x":"0","y":"107","rx":"5","ry":"5","width":"143.55","height":"86.59"}}),_c('rect',{attrs:{"x":"152.84","y":"107","rx":"0","ry":"0","width":"168.72","height":"12.12"}}),_c('rect',{attrs:{"x":"152.84","y":"123","rx":"0","ry":"0","width":"129","height":"9"}})])}):_vm._e(),_vm._l((_vm.currentPosts),function(item,i){return _c('div',{key:i,staticClass:"news__item"},[_c('div',{staticClass:"thumb",style:({
							'background-image': 'url(' + item.picture + ')',
						})},[_c('router-link',{attrs:{"to":{
								name: 'post-single',
								params: { postslug: item.slug },
							}}})],1),_c('div',{staticClass:"content"},[(item.date)?_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date._seconds,"DD MMM"))+" ")]):_vm._e(),_c('div',{staticClass:"title"},[_c('router-link',{attrs:{"to":{
									name: 'post-single',
									params: { postslug: item.slug },
								}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)])])}),(_vm.pages > 1)?_c('nav',{attrs:{"aria-label":"..."}},[_c('ul',{staticClass:"pagination"},_vm._l((_vm.pages),function(p,i){return _c('li',{key:i,staticClass:"page-iteme"},[(Number(i + 1) == _vm.page)?_c('span',{staticClass:"page-link",class:{active:i +1== _vm.page}},[_vm._v(" "+_vm._s(i + 1)+" "),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])]):_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.page = Number(i + 1)}}},[_vm._v(_vm._s(i + 1))])])}),0)]):_vm._e()],2),_c('div',{staticClass:"col-12 col-md-4"},[_c('sidebarBio',{attrs:{"bio":_vm.bio}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }