<template>
	<header
		id="header"
		class="d-flex align-items-center"
		:class="{ inner: $route.name !== 'userSite' }"
	>
		<div class="container">
			<div class="row align-items-center">
				<div class="col-3">
					<router-link
						:to="{ name: 'userSite', hash: '#hero' }"
						class="header__logo"
					>
						<img
							v-if="hero && hero.theme == 'sluga'"
							src="@/assets/img/logo.svg"
							height="64"
							alt=""
							srcset=""
						/>
						<img
							v-if="hero && hero.theme == 'zamajbutnje'"
							src="@/assets/img/politichna-partiya-za-majbutne.svg"
							height="48"
							alt=""
							srcset=""
						/>
					</router-link>
				</div>
				<div class="col-9">
					<ul class="header__nav" :class="{ opened: menu_open }">
						<li>
							<router-link
								:to="{ name: 'userSite', hash: '#news' }"
							>
								Новини
							</router-link>
						</li>
						<li>
							<router-link
								:to="{ name: 'userSite', hash: '#bio' }"
							>
								Біографія
							</router-link>
						</li>
						<li>
							<router-link
								:to="{ name: 'userSite', hash: '#program' }"
							>
								Порограма
							</router-link>
						</li>
						<li>
							<router-link
								:to="{ name: 'userSite', hash: '#contacts' }"
							>
								Контакти
							</router-link>
						</li>
					</ul>
					<div
						class="header__toggle"
						:class="{ opened: menu_open }"
						@click="menu_open = !menu_open"
					>
						<i></i>
						<i></i>
						<i></i>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	props: ["hero"],
	data() {
		return {
			menu_open: false,
		};
	},
	watch: {
		$route() {
			this.menu_open = false;
		},
	},
};
</script>

<style lang="scss">
@import "../scss/_header.scss";
</style>
