<template>
		<div class="section__contacts" id="contacts">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h2>Контакти</h2>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-12 col-md-6">
						<a :href="hero.facebook" v-if="hero.facebook" class="contacts__item">
							<svg>
								<use href="#icon_facebook"></use>
							</svg>
							<span>
								Сторінка у facebook
							</span>
						</a>
						<!-- /item -->
						<a :href="hero.youtube" v-if="hero.youtube" class="contacts__item">
							<svg>
								<use href="#icon_youtube"></use>
							</svg>
							<span>
								Youtube канал
							</span>
						</a>
						<!-- /item -->
						<a :href="hero.instagram" v-if="hero.instagram" class="contacts__item">
							<svg>
								<use href="#icon_instagram"></use>
							</svg>
							<span>
								Instagram профіль
							</span>
						</a>
						<!-- /item -->
						<a :href="'mailto:'+hero.email" v-if="hero.email" class="contacts__item">
							<svg>
								<use href="#icon_email"></use>
							</svg>
							<span>
								mail@mail.com
							</span>
						</a>
						<!-- /item -->
						<a :href="'tel:'+hero.phone" v-if="hero.phone" class="contacts__item">
							<svg>
								<use href="#icon_phone"></use>
							</svg>
                                                        <span>
								{{hero.phone}}
                                                         </span>
						</a>
						<!-- /item -->
					</div>
					<div class="col-12 col-md-6">
						<form>
							<div class="row">
								<div class="col-12 col-sm-6">
									<input
										type="text"
										class="w-100"
										placeholder="Ваше ім'я"
                                                                                v-model="form.name"
									/>
								</div>
								<div class="col-12 col-sm-6">
									<input-mask
										v-model="form.phone"
										mask="+3\8 999 999 99 99"
										maskChar=" "
										class="w-100"
										:alwaysShowMask="true"
									></input-mask>
								</div>
								<div class="col-12">
									<textarea
										class="w-100"
										name=""
										id=""
										cols="30"
										rows="5"
                                                                                v-model="form.text"
										placeholder="Текст повідомлення"
									></textarea>
								</div>
								<div class="col-12">
									<button
										type="submit"
										class="btn primary w-100"
									>
										Надіслати
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
        export default {
                props:["hero"],
                data() {
                        return {
                                form:{}
                        }
                },
        }
</script>

<style lang="scss">
$yellow: #ffdb38;
@import "../scss/bootstrap.min.css";
@import "../scss/_buttons.scss";
@import "../scss/_typography.scss";
@import "../scss/_sections.scss";
</style>